#giftCards .heroContainer {
  position: relative;
}
#giftCards .heroContainer .captions {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media (max-width: 575.98px) {
#giftCards .heroContainer .captions {
    display: none;
}
}
#giftCards .heroContainer .primaryCaption {
  color: #fff;
  background: #f07b5d;
  font-size: 14px;
  padding: 10px;
  font-weight: normal;
}
#giftCards .heroContainer .secondaryCaption {
  width: 100%;
  color: #fff;
  background: rgba(41, 46, 68, 0.85);
  font-size: 14px;
  padding: 10px;
}
@media (min-width: 768px) {
#giftCards .heroContainer .captions {
    left: 40%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
}
#giftCards .heroContainer .primaryCaption {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    margin-top: -20px;
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
}
#giftCards .heroContainer .secondaryCaption {
    padding: 20px 30px;
    text-align: left;
}
}
@media (min-width: 992px) {
#giftCards .heroContainer .primaryCaption {
    font-size: 20px;
    height: 200px;
    width: 200px;
    margin-top: -20px;
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
}
#giftCards .heroContainer .primaryCaption span {
    text-align: center;
    display: block;
}
#giftCards .heroContainer .secondaryCaption {
    font-size: 16px;
    padding: 30px 35px;
}
}
@media (min-width: 1600px) {
#giftCards .heroContainer .captions {
    left: 45%;
}
#giftCards .heroContainer .primaryCaption {
    font-size: 30px;
    height: 350px;
    width: 350px;
    margin-top: -30px;
    -webkit-transform: translateX(-45%);
            transform: translateX(-45%);
    line-height: 140%;
}
#giftCards .heroContainer .primaryCaption span {
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    display: block;
}
#giftCards .heroContainer .secondaryCaption {
    font-size: 24px;
    padding: 40px 45px;
    line-height: 140%;
}
}
#giftCards .quote {
  max-width: 700px;
  margin: auto;
}
#giftCards .quote .content {
  text-align: left;
}
#giftCards .svgIcon {
  text-align: center;
  display: block;
  margin-top: 3rem;
}
#giftCards .svgIcon svg {
  width: 100px;
  max-width: 100%;
  max-height: 75px;
  margin-bottom: 2rem;
}
#giftCards .svgIcon svg path {
  fill: #f07b5d;
}
#giftCards .hasBackground.block {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#giftCards .hasBackground.block ul {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 1rem;
}
#giftCards .hasBackground.block li {
  list-style: disc;
}