.blog .blog-header {
  position: relative;
}
.blog .blog-header #scrollDown {
  position: absolute;
  bottom: -36px !important;
}
.blog .topbar {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
.blog .topbar {
    margin-bottom: 60px;
}
}
.blog .page-title {
  padding: 60px 30px;
}
@media (min-width: 992px) {
.blog .page-title {
    padding: 90px 30px;
}
}
.blog .postSlider {
  padding: 0 30px;
}
.blog .postSlider .slick {
  margin-top: 0;
}
.blog .postSlider .sliderTitle,
.blog .postSlider .postPad__date {
  display: none !important;
}
.blog .postSlider .slick-slider {
  max-width: calc(100% - 80px) !important;
}
.blog-section {
  margin-bottom: 60px;
}
@media (min-width: 992px) {
.blog-section {
    margin-bottom: 180px;
}
}
.blog-section .blog-info {
  height: 100%;
  padding: 30px;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
.blog-section .blog-info {
    margin-bottom: 0;
    padding: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
}
.blog-section .blog-info .btn {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  margin-top: 1rem;
}
@media (min-width: 992px) {
.blog-section .blog-info .btn {
    margin-top: 2rem;
    float: right;
}
}
.blog-section .blog-title {
  margin-bottom: 3rem;
}
.blog-section .blog-title svg {
  width: 16px;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media (max-width: 991.98px) {
.blog-section .blog-title {
    margin-bottom: 1rem;
    font-size: 1rem;
}
}
.blog-section.consumer .blog-info {
  background: #f07b5d;
}
@media (min-width: 992px) {
.blog-section.consumer .blog-info {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
}
.blog-section.consumer .blog-title {
  color: #333333;
}
@media (min-width: 992px) {
.blog-section.consumer .postSlider {
    padding-left: 0;
}
}
.blog-section.business .blog-info {
  background: #292E44;
}
@media (min-width: 992px) {
.blog-section.business .blog-info {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
}
.blog-section.business .blog-title, .blog-section.business p {
  color: #fff;
}
@media (min-width: 992px) {
.blog-section.business .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.blog-section.business .postSlider {
    padding-right: 0;
}
}