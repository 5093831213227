#info img[data-v-38d28187] {
  width: 100%;
}
#info .router-link-active[data-v-38d28187] {
  color: white !important;
}
#info #contatti-contattaci[data-v-38d28187] {
  font-size: 0.95rem;
}
#info #contatti-contattaci .contactTitle[data-v-38d28187] {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
#info #contatti-contattaci a[data-v-38d28187] {
  color: #333333;
  cursor: pointer;
}
#info #contatti-contattaci a[data-v-38d28187]:hover {
  color: #f07b5d;
}
#info #contatti-contattaci svg[data-v-38d28187] {
  margin-right: 10px;
  width: 20px;
  height: auto;
}
#info #contatti-contattaci svg *[data-v-38d28187] {
  fill: #f07b5d;
}
#info .sectionTitle[data-v-38d28187]:first-letter {
  text-transform: uppercase;
}