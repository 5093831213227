@media (min-width: 768px) {
.blog-category .container-fluid {
    padding-left: 60px;
    padding-right: 60px;
}
}
.blog-category .topbar {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
.blog-category .topbar {
    margin-bottom: 60px;
}
}
.blog-category .content {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
.blog-category .content {
    margin-bottom: 120px;
}
}
.blog-category .content .postPad {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
.blog-category .content .postPad {
    margin-bottom: 60px;
}
}
.blog-category .content .tags-title {
  font-size: 1.5rem;
  display: block;
  margin-bottom: 2rem;
  font-weight: bold;
}
.blog-category .content .tag {
  border: 1px solid #f07b5d;
  border-radius: 20px;
  padding: 2px 18px;
  font-weight: 400;
  font-size: 18px;
  color: #f07b5d;
  display: inline-block;
  margin: 0 1.25rem 0.75rem 0;
  cursor: pointer;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  background-color: transparent;
}
.blog-category .content .tag:hover, .blog-category .content .tag.active {
  color: #fff;
  background-color: #f07b5d;
}
.blog-category .content .search-posts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
  position: relative;
}
.blog-category .content .search-posts input, .blog-category .content .search-posts button {
  border-radius: 1.5rem;
}
.blog-category .content .search-posts input {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #F2F2F2;
  margin-right: 1rem;
  padding-left: 4rem;
}
.blog-category .content .search-posts .search-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 36px;
  height: auto;
  padding-right: 14px;
  border-right: 1px solid #CCCCCC;
}
.blog-category .content .search-posts .search-icon path {
  fill: #333333;
}
.blog-category .hidden {
  display: none;
}
.blog-category .load-more {
  min-width: 100%;
  cursor: pointer;
  margin-bottom: 60px;
  color: #007bff;
  text-align: right;
  font-weight: bold;
  padding-right: 60px;
}
.blog-category .load-more span {
  text-decoration: underline;
}
.blog-category .load-more svg {
  width: 14px;
  height: auto;
  margin-right: 6px;
}
.blog-category .load-more svg path {
  stroke: #007bff;
}
.blog-category.consumer .tag {
  color: #f07b5d;
  border-color: #f07b5d;
}
.blog-category.consumer .tag:hover, .blog-category.consumer .tag.active {
  color: #fff;
  background-color: #f07b5d;
}
.blog-category.business .tag {
  color: #292E44;
  border-color: #292E44;
}
.blog-category.business .tag:hover, .blog-category.business .tag.active {
  color: #fff;
  background-color: #292E44;
}