.faq .pageTitle {
  color: #f07b5d;
  margin-bottom: 2rem;
}
@media (max-width: 767.98px) {
.faq .container {
    padding-left: 0;
    padding-right: 0;
}
}
@media (max-width: 991.98px) {
.faq .pageTitle {
    margin-bottom: 0;
}
}
@media (min-width: 992px) {
.faq .pageTitle {
    margin-bottom: 3rem;
}
.faq .torna-indietro {
    padding-top: 2rem;
}
}