.landingPinkPalace .container {
  max-width: 1100px !important;
}
.landingPinkPalace .content {
  padding-top: 40px;
  padding-bottom: 40px;
}
.landingPinkPalace .heroCol {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}
.landingPinkPalace .heroCol .hero--title {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
}
@media (min-width: 768px) {
.landingPinkPalace .heroCol .hero--title {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 30px;
}
}
.landingPinkPalace .heroCol .hero--subtitle {
  font-size: 14px;
}
.landingPinkPalace .title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
.landingPinkPalace .title {
    font-size: 28px;
    margin-bottom: 40px;
}
}
.landingPinkPalace .heroLogo {
  max-width: 100%;
  width: 300px;
}
.landingPinkPalace .aon {
  color: #E31836;
  font-weight: 600;
}
.landingPinkPalace .white, .landingPinkPalace .white * {
  text-decoration: none !important;
}
.landingPinkPalace .icons {
  text-align: center;
}
.landingPinkPalace .icons .icon {
  margin-bottom: 40px;
}
.landingPinkPalace .icons .text {
  max-width: 200px;
  margin: auto;
}
.landingPinkPalace .primary {
  font-weight: 600;
  font-size: 1.25em;
}
.landingPinkPalace .first {
  font-size: 1.35em;
}