.accordion .card-header {
  padding: 1rem 0.5rem;
  height: auto;
}
.accordion .card-header a {
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
}
.accordion .card-header * {
  font-size: 18px;
  color: #0077CA;
  font-weight: bold;
}
.accordion .card-text {
  font-size: 16px;
  line-height: 140%;
}