.landingScalapay .container {
  max-width: 1100px !important;
}
.landingScalapay .content {
  padding-top: 40px;
  padding-bottom: 40px;
}
.landingScalapay .title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
.landingScalapay .title {
    font-size: 28px;
    margin-bottom: 40px;
}
}
.landingScalapay .heroLogo {
  max-width: 100%;
  width: 300px;
}
.landingScalapay .aon {
  color: #E31836;
  font-weight: 600;
}
.landingScalapay .white, .landingScalapay .white * {
  text-decoration: none !important;
}
.landingScalapay .icons {
  text-align: center;
}
.landingScalapay .icons .icon {
  margin-bottom: 40px;
}
.landingScalapay .icons .text {
  max-width: 200px;
  margin: auto;
}
.landingScalapay .primary {
  font-weight: 600;
  font-size: 1.25em;
}