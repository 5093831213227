.doublecheck .card-header-tabs {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.doublecheck .card-header-tabs * {
  color: #333333;
}
.doublecheck .card-header .nav-item svg {
  width: 32px;
  height: auto;
}