.landingBackToItaly #hero {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.landingBackToItaly .heroFullRow {
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  background: #f07b5d;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
}
.landingBackToItaly .copyScadenza {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.landingBackToItaly .stellaMesi {
  position: relative;
}
.landingBackToItaly .stellaMesi svg {
  width: 40px;
  height: auto;
}
.landingBackToItaly .stellaMesi .value {
  z-index: 1;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  margin: auto;
  width: 100%;
  height: auto;
  text-align: center;
}
@media (min-width: 992px) {
.landingBackToItaly .heroFullRow {
    margin-top: 60px;
    padding: 15px 30px;
    font-size: 38px;
}
.landingBackToItaly .copyScadenza {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.landingBackToItaly .stellaMesi svg {
    width: 80px;
}
}
@media (min-width: 1200px) {
.landingBackToItaly .stellaMesi {
    padding: 0 1.5rem;
}
}
.landingBackToItaly .white, .landingBackToItaly .white * {
  text-decoration: none !important;
}
.landingBackToItaly button.btn-secondary {
  min-width: 200px;
}