.quote {
  text-align: center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.quote .content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 1rem;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500 !important;
}
.quote svg {
  width: 32px;
  height: 32px;
}
@media (min-width: 992px) {
.quote svg {
    width: 60px;
    height: 60px;
}
}
.quote svg path {
  fill: #f07b5d;
}
.quote .start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.quote .stop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}