.newsletterBox {
  margin: 0 auto;
  width: 90%;
  max-width: 700px;
  text-align: left;
}
.newsletterBox label {
  line-height: 140%;
  font-size: 12px;
}
.newsletterBox .newsletterTitle {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}
.newsletterBox .newsletterTitle .small {
  font-size: 16px;
  font-weight: bold;
}
.newsletterBox .emailBlock {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: auto;
  max-width: 360px;
  margin-right: 1.5rem;
}