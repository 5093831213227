.riscattaSalabam {
  display: block;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 6rem;
}
@media (max-width: 767.98px) {
.riscattaSalabam {
    padding-top: 2rem;
}
}
.riscattaSalabam .listDisc li:not(:last-child) {
  margin-bottom: 10px;
}
.riscattaSalabam .redeemFields {
  margin: 0 auto;
  max-width: 420px;
}
.riscattaSalabam .checkboxes {
  margin: auto;
  max-width: 320px;
}