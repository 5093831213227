.doublecheck .content {
  padding: 2rem 0 3rem 0;
}
@media (min-width: 768px) {
.doublecheck .content {
    padding: 4rem 0 6rem 0;
}
}
.doublecheck .title {
  color: #f07b5d;
  margin: 3.5rem 0;
  text-align: center;
}
.doublecheck .subtitle {
  color: #333333;
  font-weight: bold;
  margin-bottom: 2rem;
  font-size: 1.25rem;
}
.doublecheck--section {
  display: block;
  text-align: center;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
.doublecheck--section {
    padding: 0 5%;
    margin-bottom: 4rem;
}
}
.doublecheck--section table {
  margin: 0 auto;
  max-width: 600px;
}
.doublecheck--section table td {
  padding: 0.25rem 1rem;
  text-align: left;
}
.doublecheck--section table .name {
  font-weight: 600;
}
.doublecheck--section table .customInputBlock {
  margin-bottom: 1rem;
}
.doublecheck--section table .customInputBlock input {
  border: 1px solid #e9ecef;
}
.doublecheck--section table .customInputBlock .form-text {
  padding-bottom: 0.35rem;
  font-weight: normal;
}
.doublecheck--section table .customInputBlock .custom-checkbox .custom-control-label {
  font-size: 0.85rem;
  line-height: 140%;
}
.doublecheck--section table.info td {
  width: 50%;
}
.doublecheck--section table.form td {
  vertical-align: middle;
}
.doublecheck--section table.form tr:last-child td {
  padding-top: 1rem;
  vertical-align: top;
}
.doublecheck--section table.form .value {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-bottom: 1rem;
}