#header-small {
  position: relative;
}
#header-small .scrollDown {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}