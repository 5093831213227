#info img[data-v-e93bcf8e] {
  width: 100%;
}
#info .incentiveIcons .box[data-v-e93bcf8e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#info .incentiveIcons .icon[data-v-e93bcf8e] {
  min-width: 60px;
  width: 60px;
  height: 60px;
  margin-right: 1rem;
  padding: 15px;
  border: 1px solid #F2F2F2;
  border-radius: 50%;
}
#info .incentiveIcons .icon svg[data-v-e93bcf8e] {
  width: 100%;
  height: 100%;
}
#info .incentiveIcons .icon svg path[data-v-e93bcf8e] {
  fill: #333333;
}