.checklist {
  display: block;
}
.checklist svg,
.checklist .checklist_index {
  color: #f07b5d;
  font-size: 24px;
  font-weight: 700;
  width: 24px;
  margin-right: 18px;
}
.checklist .checklist_index:after {
  content: ".";
}
.checklist li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}
.checklist li .checklist_title {
  color: #f07b5d;
  font-weight: bold;
  display: block;
  min-width: 100%;
  font-size: 18px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.checklist li span {
  font-size: 16px;
}
.checklist li .checklist_item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: calc(100% - 42px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}