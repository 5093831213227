.form .customInputBlock input:focus ~ .formClear {
  top: 18px;
  -webkit-transform: none;
          transform: none;
}
.form input,
.form select,
.form textarea,
.form .custom-select,
.form .dropdown,
.form .form-control .input-group {
  border: 1px solid #efefff !important;
  margin-bottom: 1rem;
}